@import "../styles/index.scss";

.iccLabel {
  background-color: #f3f2be;
  border-radius: 5px;
  margin: 2px;
  padding: 1px;
  width: min-content;
  float: left;
  color: black;
}

.iccLabel:hover {
  background-color: yellow;
  cursor: pointer;
  color: black;
}
