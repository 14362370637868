@import "../styles/index.scss";

body {
  font-family: Arial, Helvetica, sans-serif !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey !important;
  opacity: 0.5 !important;
}

.btn {
  width: 125px;
}

//text-align:center;

.btnText {
  position: relative !important;
  display: flex !important;
  left: 50% !important;
  top: 7px !important;
  transform: translate(-50%, 0%) !important;
}

.newCard-form-text,
.boardDate {
  text-align: left;
  vertical-align: middle;
}

.boardFormError {
  color: red;
}

.board-form {

  &__select {
    margin-right: 0px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif !important;
  }
}

.ant-select-selector {
  border: none !important;
}