@import "../styles/index.scss";

.title {
  font-weight: bold;
  font-size: 1.1em;
}

.celda {
  float: left;
  margin-right: 20px;
}

.btn-save-privileges {
  text-align: right;
}
