
body {
 background-image: url("../../assets/img/jpg/original.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-color: #cccccc;
}


.signInForm {
  width: 360px;
  padding: 35vh 0 0;
  margin: auto;
  text-align:center;
}

.login-logo{
  position: relative;
  width:100%;
  text-align: center;
  left:-20px;
}


@media (min-width: 800px) {
  .signInForm {
    padding: 15vh 0 0;
  }
}

.login-form {
  position: relative;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 35px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
.login-logo{
    text-align:center;
    margin: 20px;
}
