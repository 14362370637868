@import "../styles/index.scss";

#checkbox label {
  margin-inline-start: 0px;
  display: flex;
  justify-content: space-between;
}

#checkbox label span{
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
