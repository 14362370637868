@import "../styles/index.scss";

.menu-top {
  display: flex;

  button {
    background-color: transparent;
    color: $font-light;
    border: 0;

    &:hover {
      color: $primary-color;
    }
    &focus,
    &active {
      color: $font-light;
    }
  }

  &__left {
    &-logo {
      width: 200px;
      padding: 0 10px;
    }
  }

  &__right {
    position: absolute;
    right: 1vw;
    top: 0;
  }
}

.main {
  background-color: white;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh 3vw;
}

.profile-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 22;
}

.btnLogout{
  display: flex;
  padding: 1vh 1vw;
  font-size: 20px;
  margin-bottom: 1vh;
}

.impersonateBanner {
  background: rgba(255, 0, 0, 0.7);
  color: white;
  margin-right: 15px;
  line-height: 30px;
  padding: 6px;
  border-radius: 5px;
}

.gestButton {
  border: 1px solid $primary-color;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  margin-top: 5px;
  color: $primary-color;
}
