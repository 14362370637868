@import "../styles/index.scss";

.register-form{
    
	&__item{
			//background-color: red;
			position: relative;
			display: inline-block;
			width:100%;
			text-align: justify;
	}

	&__select{
			margin-right: 50px;
	}

	&__select-label{
			margin-right: 15px;
	}
}

.userForm-createBtn{
	text-align:right;
}
