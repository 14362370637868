body {
		margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	}

.arrow {
  -webkit-clip-path: polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%);
  clip-path: polygon(0 0, 95% 0%, 100% 50%, 95% 100%, 0 100%);
}

.form-group.row {
  margin: 10px 0px 10px 0px !important;
}

.float-right {
  float: right !important;
}