@import "./variables";
@import "./colors";

div.ant-notification {
    text-align: left;
}

.button-panel {
    text-align: center;
}
