@import "../styles/index.scss";

.register-form{
    
	&__item{
			//background-color: red;
			position: relative;
			display: inline-block;
			width:100%;
			text-align: justify;
	}

	&__select{
			margin-right: 50px;
	}

	&__select-label{
			margin-right: 15px;
	}
}

.userForm-select{
	position: relative;
	display: inline-block;
}

.userForm-selectMargin{
	margin-bottom: 15px;
}

.userForm-checkbox{
	text-align: right;
}
.userForm-createBtn{
	text-align:right;
}

.form-select{
	border: 1px solid #d9d9d9;
	padding: 6px 12px;
	line-height: 1.5715;
	border-radius: 2px;
	/*border-right: 0;*/
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	background-color: #fafafa;
	color: rgba(0,0,0,0.65);
}

.form-select-input{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;   
}
