@import "../styles/index.scss";

.menu-sider {
  position: fixed;
  left: 0;
  top: 65px;
  height: 100vh;
  overflow: auto;
}

.btnHome {
  width:100% !important;
}