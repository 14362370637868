@import "./index.scss";

.layout-admin {
  /*transition-margin-left: 0.2s;*/

  &__header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    display: flex !important;
    padding: 0 !important;
    height: 65px !important;
  }

  &__content {
    width: 100% !important;
    min-height: calc(
      100vh - 65px
    ) !important; // 100% de la pantalla (view port) - altura header
    padding: 90px 25px 25px 25px !important;
  }
  &__footer {
    padding: 20px !important;
  }
}
