@import "../styles/index.scss";

body {
  font-family: Arial, Helvetica, sans-serif !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey !important;
  opacity: 0.5 !important;
}

.btn {
  width: 125px;
}

//text-align:center;

.btnText {
  position: relative;
  display: flex;
  left: 50%;
  top: 7px;
  transform: translate(-50%, 0%);
}

.newCard-form-text,
.boardDate {
  text-align: left;
  vertical-align: middle;
}

.boardFormError {
  color: red;
}